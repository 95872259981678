<template>
  <div
    v-if="dataLoaded"
    :class="{ 'all-wrap': true, 'white-theme': whiteTheme }"
  >
    <Header :title="'Gallery'" />

    <router-view
      @artist-hover="makeWhite()"
      @normal-state="makeNormal()"
      v-slot="{ Component, route }"
    >
      <transition :name="route.meta.transition || 'pages-fade'">
        <component :is="Component" />
      </transition>
    </router-view>

    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue"
import Footer from "./components/Footer.vue"
import axios from "axios"

export default {
  name: "App",
  components: { Header, Footer },
  data() {
    return {
      whiteTheme: false,
      apiUrl: "https://159f-gallery.com/panel/api/",
      dataLoaded: false,
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll)
  },
  mounted() {
    // if (process.env.VUE_APP_API_URL != undefined)
    //   this.apiUrl = process.env.VUE_APP_API_URL
    let lang = localStorage.getItem("159f_lang")
    if (lang == undefined) lang = this.params.lang

    axios.get(this.apiUrl + "all?lang=" + lang).then((response) => {
      let data = response.data
      data.params.lang = lang
      this.$store.dispatch("setExhibitions", data.exhibitions)
      this.$store.dispatch("setFairs", data.fairs)
      this.$store.dispatch("setArtists", data.artists)
      this.$store.dispatch("setParams", data.params)
      let artists = this.$store.getters.getArtists(false)
      artists.forEach((a) => {
        // console.log(a.startPhoto)
        if (a.startPhoto) {
          const artistCover = new Image()
          artistCover.src = a.startPhoto
        }
      })
      this.dataLoaded = true
    })
  },
  computed: {
    params() {
      return this.$store.getters.getParams()
    },
  },
  methods: {
    handleScroll() {},
    makeWhite() {
      this.whiteTheme = true
    },
    makeNormal() {
      this.whiteTheme = false
    },
  },
}
</script>
